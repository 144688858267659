<template>
  <v-list
    width="100%"
    nav
    dark
    color="secondary"
  >
    <div
      v-for="item in menuItems"
      :key="item.title"
    >
      <v-list-item
        class="my-0"
        :value="$route.name === item.route"
        :to="item.route"
        :exact="item.exact !== false"
        @click="handleNavigationClick(item)"
      >
        <v-list-item-avatar
          v-if="item.icon"
          class="my-0"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-wrap"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'KMenu',
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleNavigationClick(item) {
      if (item.children && item.children.length) {
        this.$emit('openSubMenu', item);
      }
    },
  },
};
</script>
